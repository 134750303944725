import React from 'react';
import './Budgetly.css';
import '../App.css';
import OldBudget from '../photos/budgetly/OldBudget.png';
import NewOnb from '../photos/budgetly/NewOnb.png';
import NewGoal from '../photos/budgetly/NewGoal.png';
import Navbar from './Navbar.js' ;
import Menubar from './Menubar.js' ;
import ImageWithPlaceholder from './ImageWithPlaceholder'; 
import Placeholder from '../photos/loading.png'; 

function Budgetly() {
  return (

    <div>
      <Navbar/>
      <Menubar/>
      <div className="Budgetly">

    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>IOS App Development</p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>Budgetly</h1>
    <p>10.2022 - 02.2023</p>
    <p><b>Role</b>: Concept, Design, App Development</p>
    <p><b>Tools</b>: Figma, XCode</p>
    </div>
    </div>

    <div className='ProjImg2'>
    </div>

    <p>In the "Mobile Application Development 2" course, we created a budgeting app specifically 
      designed to assist university students in managing their finances more effectively. Although 
      the course primarily emphasized development skills rather than UI/UX design, our team, having a keen interest in user interface and user experience, made a concerted effort to enhance the app's design and usability. </p>

    <h2>01. OLD DESIGN PROTOTYPE </h2>
    This is our first high fidelity prototype that we ended up coding and submitting for our assignment. 
    Due to time constraints, we were unable to dedicate extensive time to the design aspect, as our main 
    goal was to complete the coding on schedule. Ultimately, we successfully implemented all the features 
    as outlined in our initial prototype. We received great feedback from both our professor and 
    fellow students, and we received a grade of 1.0 for the project.
    <img src={OldBudget} className='MyHome' alt="asset" style={{marginTop: "5%"}}/>

    <h2>02. THE APP </h2>
    <p>Click here for a walkthrough of the app</p>
    <a href="https://drive.google.com/file/d/16pUOWdEleISOxhReF3ajCRr21F1g2Dkq/view?usp=sharing" target="_blank" rel="noopener noreferrer">
        <button className='VideoBtn'>Go to video</button>
      </a>

     
    <h2>03. NEW DESIGN </h2>
    <h3>Onboarding</h3>

    {/* <img src={NewOnb} className='MyHome' alt="asset"/> */}
    <ImageWithPlaceholder className='MyHome'
        src={NewOnb}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />

    <h3>Setting a goal and challenge</h3>

    {/* <img src={NewGoal} className='MyHome' alt="asset"/> */}
    <ImageWithPlaceholder className='MyHome'
        src={NewGoal}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />

    </div>


     <div style={{height: "8rem"}}></div>
     </div>
);
}

export default Budgetly;