import React from 'react';
import Navbar from './Navbar.js' ;
import './Schule.css'
import Steffen from '../photos/schule/Steffen.png'
import Lukas from '../photos/schule/Lukas.png'
import Sophia from '../photos/schule/Sophia.png'
import Foxes from '../photos/schule/Foxes.svg'
import LFPro from '../photos/schule/LFPSchule.png'
import LFPro2 from '../photos/schule/LFPSchule2.png'
import Backgrounds from '../photos/schule/Backgrounds.png'
import Wireframe from '../photos/schule/Wireframe.png'
import Profile from '../photos/schule/Profile.png'
import Menubar from './Menubar.js';
import ImageWithPlaceholder from './ImageWithPlaceholder'; 
import Placeholder from '../photos/loading.png'; 

function Schule() {
  return (

    <div>
      <Navbar/>
      <Menubar/>
      <div className="Milton">

    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>Spuren Im Netz</h1>
    <p>10.2023 - 02.2024</p>
    <p><b>Role</b>: Concept, Design</p>
    <p><b>Tools</b>: Figma, Illustrator</p>
    </div>
    </div>

    <div className='SchuleImg'>
    </div>

    <p>
    In cooperation with School 4.0 and a primary school teacher, we set out to build an exciting and interactive application by the end of the semester that can be used in a classroom 
    setting. The project aims to contribute to promoting media literacy in primary schools across Germany. This freely available application is intended to motivate teachers from other 
    schools to integrate it into their teaching and impart digital skills to children
    </p>
    <p>
    The main goal of the project was to develop an application that not only entertains children but also provides a learning experience. Through the playful and interactive design of 
    the application, we aim to stimulate students' interest in media literacy and digital topics. At the same time, the application is intended to provide teachers with a tool to make 
    their lessons more dynamic and contemporary while supporting the impartation of digital competencies.
    </p>
    {/* <h2> 01. THE WEB APP </h2>
    <p>Before we delve into the details of this project, let's start with a walkthrough of the webapp.</p>

    <hr/> */}

    <h2> 01. HIGH FIDELITY PROTOTYPE </h2>

    <p>Let's start by taking a look at the end results</p>

    <a href="https://www.figma.com/proto/4oUgWHqiOGxE8lnCsqiwDV/Schule-4.0?page-id=240%3A1111&type=design&node-id=918-29799&viewport=480%2C140%2C0.03&t=IVkQAYkSgO0Sl85B-1&scaling=scale-down&starting-point-node-id=918%3A29799&show-proto-sidebar=1&mode=design" target="_blank" rel="noopener noreferrer">
        <button className='Prototype'>Go to Prototype</button>
    </a>

    <iframe className="iFrame" style={{border: "1px solid rgba(0, 0, 0, 0.1)"}} 
    width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F4oUgWHqiOGxE8lnCsqiwDV%2FSchule-4.0%3Fpage-id%3D240%253A1111%26type%3Ddesign%26node-id%3D918-29799%26viewport%3D4200%252C-156%252C0.28%26t%3DYRq6mRJ8qvGNAZSg-1%26scaling%3Dscale-down%26starting-point-node-id%3D918%253A29799%26show-proto-sidebar%3D1%26mode%3Ddesign" allowfullscreen></iframe>


    <hr />

    <h2> 02. DESIGN PROCESS </h2>
    <h3>Analysis</h3>
    <p>In order to come up with an idea, a crucial initial step involves gaining a deep understanding of our users. It was evident to us from the beginning that our product will be 
        used by, or at least tested by, third graders from an elementary school in Ludwigsburg. With this information provided, we started defining our user personas. Our objective 
        in this phase was to develop three distinct user profiles for third graders, each reflecting diverse personalities, challenges, family dynamics, and financial backgrounds.</p>
    
    {/* <img src={Steffen} className='Persona' alt="asset" /> */}
    <ImageWithPlaceholder className='Persona'
        src={Steffen}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Persona 1"
      />

    {/* <img src={Lukas} className='Persona' alt="asset" />
    <img src={Sophia} className='Persona' alt="asset" /> */}

    <ImageWithPlaceholder className='Persona'
        src={Lukas}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Persona 2"
      />

    <ImageWithPlaceholder className='Persona'
        src={Sophia}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Persona 3"
      />


    <h3>Ideation</h3>
    <p>After some more brainstorming, as well as discussion within the group to find out all members’ interests and strengths, we opted to create a web application. To optimize 
        efficiency, we divided the team into two groups—designers and developers. However, all members actively participated in the ideation process and decision-making to ensure 
        a collaborative approach. As we already made some design decisions based on our research of our audience, we quickly agreed on some key aspects for our app: 
    </p>
    <p>
    • We need to have a mascot
    <br></br>
    • We aim to tackle two scenarios: Online Bullying and Messaging with Strangers
    <br></br>
    • To have a colorful and visual design 
    <br></br>
    • For the scope of this project, the app does not need to be responsive, as it will only be used on a specific tablet 
    </p>    



    <h3>Low Fidelity Prototype</h3>
    <p>The core concept of our idea was sketched out using hand drawn sketches. Unfortunately most of the sketches  
        have gone missing during the semester but here are some examples. </p>

        {/* <img src={LFPro} style={{width: "66%"}} className='' alt="asset" />
        <img src={LFPro2} style={{width: "29%"}} className='' alt="asset" /> */}
    
    <ImageWithPlaceholder style={{width: "66%"}}
        src={LFPro}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />

    <ImageWithPlaceholder  style={{width: "29%"}}
        src={LFPro2}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />
        

    <h3>Wireframe</h3>
        
    <p> Then we created a quick wirefram using rough outlines with gray boxes. 
        This allowed us to quickly make changes and see what would work. We quickly decided on a layout consisting of 
        a dialogue box, phone simulator and multiple choice questions, since we thought this would be an easy way for 
        the children to receive input and learn from the feedback.
    </p>

    {/* <img src={Wireframe} className='SchulePhotos' alt="asset" /> */}
    <ImageWithPlaceholder className='SchulePhotos' 
        src={Wireframe}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />


    <h3>Design Elements</h3>
    <p>We created a storyline with a fox called Pixel. Thereby, I ultimately created a face for this fox as well as additional equipment 
        and items to provide the children with a good and engaging story they could get motivated by. 
    </p>
    <p>
    Below are some design elements connected to the storyline.They were all created using Adobe Illustrator. Since the main target group 
    for our app are third graders, we decided to make the story as visual as possible. We used colorful cartoon-like illustrations with 
    simplified shapes and colors as a way to get the children’s attention and to speak a visual language they would understand. It was 
    important for us to make these illustrations and characters playful to indicate that it is an application made to be used by children. 
    </p>
    <p>
    The design of Pixel can be seen below, he acts as a guide and mentor for the children throughout the application. Next to him is the 
    antagonist, the bad fox, which gets introduced in unit 2.</p>

    {/* <img src={Foxes} className='SchulePhotos' alt="asset" /> */}
    <ImageWithPlaceholder className='SchulePhotos' 
        src={Foxes}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />

    <p>I also illustrated environments where different parts of the story act out. The images below show Pixel’s office, the forest outside of the city
        , and the bad fox's place. 
        These were all created to further engage the children to make them interested in the story.</p>
   
    {/* <img src={Backgrounds} className='SchulePhotos' alt="asset" /> */}
    <ImageWithPlaceholder className='SchulePhotos' 
        src={Backgrounds}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />

    <hr/>
   

    <h2>03. USER TESTING</h2>

    <h3>The Test</h3>

    <p>Once we had built the functional prototype, the next step was the user test in the elementary school. Thanks to our cooperation with a teacher, we were able to visit an elementary school near Ludwigsburg and test our application together with him and 24 children. We made the following considerations before the user test:
    </p>
    <p>The children should first try out the app in pairs. This simplifies the handling of the app and the operation of the pupils' devices.
    While the children are using the app, we talk to them and get even more feedback for our project.
    After the user test, a poster is hung on the room's blackboard and the children can give us feedback on a maximum of three questions using colorful dot stickers.
    As this is still a very young target group, we have decided to use the grading system that the children already know as a scale.
    </p>

    <p>With the user test, we wanted to find out whether the children not only have fun with the application, but whether there is also a learning effect. 
    </p>

    <h3>Results</h3>

    <p>
    We took the feedback from the user test to heart and were able to improve a few things by the end of the semester.
    </p>

    <p> 
    • More detailed social media feed 
    </p>
    <p>
    It was difficult for some students to find information about the stranger in the social media feed. If they have 
    never interacted with social media, it might not be clear that the pictures were taken in the past but also which 
    pictures were uploaded more recently. To prevent misunderstandings, each post was tagged with a date to create a readable timeline.
    </p>

    {/* <img src={Profile} className='SchulePhotos' alt="asset" /> */}
    <ImageWithPlaceholder className='SchulePhotos' 
         src={Profile} 
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="asset"
      />

    <p>• Increase difficulty </p>
    <p>
    A lot of the students were able to finish the exercises more quickly than we thought. To achieve that, we changed the wording in some of the questions.
    </p>

    <p>
    • Result Screen
    </p>
    <p>
    The result screen lacked details. After completing the unit, it was difficult for the students to remember the 
    exact question they answered and why they might have had trouble selecting the correct answer. The result screen now includes more details about which 
    questions they answered and how many tries it took them to find the correct answer.
    </p>

    <h3>Possible Improvements</h3>

    <p>
    • Voiceover
    </p>

    <p>
    To create more of an immersive experience for the children, it would have been great to equip Pixel the Fox with a voice by 
    adding sound elements. This might also prevent the children from skipping over the texts and help those students that might 
    struggle reading.
    </p>

    <p>• Prevent the children from skipping over the text</p>
    <p> During the user test, we observed that some of the students did not bother reading the questions and instead just clicked on the answers until they found the right one. To prevent this, we had the idea to change how we present the questions to the children. Instead of showing them the question and answers at once, they first only see the question and once they finish reading it they can continue to view the possible answers.
    This could be further restricted. If for example they had to interact with the social media simulator in order to find the answer, they would have to click on the simulator first in order for the answers to become visible.
    </p>

    <p>• Images in social media feed </p>
    <p>For a lot of the students and also visitors at MediaNight, it was not clear that the images in the stranger’s social media feed were elements that they could interact with. That meant that they missed out on information they 
        needed to answer the question without guessing. The design of how we showcase these images could be adapted to make them look more interactable. 
    </p>

    <hr/>
    <h2> 04. LEARNINGS </h2>
    <p>The idea of working on a product specifically designed for children, one that goes beyond being a mere conceptual prototype and is intended for actual use, seemed exciting for me. Thanks to the support from our stakeholders, teachers and even students from last semester, we started the project with a strong foundation and a positive momentum. Ultimately, we successfully achieved our goals, delivering a product that reflected the effort and dedication of all team members. 
    </p>
    <p>
    Taking part in this project has been a valuable experience, contributing not only to the improvement of my technical skills but also the development of my soft skills. This semester, I have found the experience particularly beneficial as we had the chance to participate in weekly meetings with stakeholders and attend workshops focused on UX and Gamification. These lessons have significantly enriched my understanding, leading to a great improvement in the user experience of our app.
    </p>

    <div style={{height: "8rem"}}></div>

   
    </div>

   


    </div>
);
}

export default Schule;