import React from 'react';
import Navbar from './Navbar.js' ;

function Team() {
  return (

    <div>
      <Navbar/>
      <div className="Budgetly">

    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}> Full Stack Development</p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>Team Manager</h1>
    <p>10.2023 - 02.2024</p>
    <p><b>Role</b>: Concept, Full Stack Development</p>
    <p><b>Tools</b>: Intellij IDEA </p>
    </div>
    </div>

    <div className='ProjImg2'>
    </div>

    <p>As part of the course "Mobile Application Development 2", we .</p>

    </div>


    </div>
);
}

export default Team;