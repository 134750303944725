import React from 'react';
import './Milton.css';
import '../App.css';
import Navbar from './Navbar.js' ;
import MiltonIA from '../photos/MiltonIA.svg';
import MiltonWireframe from  '../photos/MiltonWireframe.png';
import MiltonPro from '../photos/milton/MiltonPro.png';
import Menubar from './Menubar.js';
import MiltonLFD from '../photos/milton/MiltonLFD.png';
import ImageWithPlaceholder from './ImageWithPlaceholder'; 
import Placeholder from '../photos/loading.png'; 

function Milton() {
  return (

    <div >
      <Navbar/>
      <Menubar/>

   <div className="Milton">
   
    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>Milton</h1>
    <p>10.2022 - 02.2023</p>
    <p><b>Role</b>: Concept, Graphic Design, UI Design</p>
    <p><b>Tools</b>: Figma, Illustrator</p>
    </div>
    </div>

    <div className='ProjImg'>
    </div>
    
    <p>As part of the course "User Interface Design", we created a prototype for a learning 
      application which aims to make the topic Quantum Computing less intimidating 
      and make learning more efficient with a user-friendly UI.
    By combining different learning styles, a reward system and 
    a micro universe to explore and unlock, the goal was to make the users feel excited 
    and eager to learn more.</p>
    
      <h2>01. HIGH FIDELITY PROTOTYPE </h2>
      <p>Let's start by taking a look at the end results</p>
      <p className='Hint'><b>Hint:</b> You can choose the flows on the right bar to see each feature more clearly</p>
      <a href="https://www.figma.com/proto/2i9FLhBsXMKQlGOwk8Jclx/UID?page-id=547%3A2371&type=design&node-id=1313-10847&viewport=325%2C232%2C0.02&t=72CjTZT1ncTCIIC4-1&scaling=scale-down&starting-point-node-id=1244%3A2954&show-proto-sidebar=1&mode=design" target="_blank" rel="noopener noreferrer">
        <button className='Prototype'>Go to Prototype</button>
      </a>
       <iframe className="iFrame" style={{border: "1px solid rgba(0, 0, 0, 0.1)"}} 
          width="100%" height="450" 
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2i9FLhBsXMKQlGOwk8Jclx%2FUID%3Fpage-id%3D547%253A2371%26type%3Ddesign%26node-id%3D1313-10847%26viewport%3D529%252C-64%252C0.03%26t%3DcxKhhQ2PlQDgnMQB-1%26scaling%3Dscale-down%26starting-point-node-id%3D1244%253A2954%26show-proto-sidebar%3D1%26mode%3Ddesign" allowfullscreen>  
          </iframe>
     
     <hr/>
      <h2>02. DESIGN PROCESS</h2>
     
      <p>We covered all main stages of the UX/UI cycle by
      implementing some design thinking methods like the 
      Crazy 8's.
      We developed personas, a low-fidelity prototype, 
      a wireframe, a design system and a high-fidelity prototype.
      During every stage we conducted some user research, 
      specifically interviews and surveys.
      </p>

      <p> Our biggest challenges: </p>
      <p>
      • Making a learning app engaging as most young people nowadays have short attention span <br></br>
      • To make the topic Quantum Computer less intimidating and create a user-friendly experience</p>

    <h3>Persona</h3>
    <p>For our first presentation, we created two personas: Lara and Timm.   </p>
    <p> Lara is 20, works in a café and doesn't know much about technology, let alone quantum computers. 
      She <b>spends a lot of time on social media</b> and loves to <b>listen to music</b> and <b>go to festivals</b>. 
      Her goal is to <b>become an influencer</b> one day. 
      Lara is very <b>extroverted</b> and <b>emotional</b>, 
      and her favorite apps are TikTok and Instagram. Her life motto is: “Live, laugh, love”.
      </p>
      <p>
      Timm, on the other hand, is 24 years old and, since he 
      completed his bachelor's degree in computer science, 
      has been working as a <b>software engineer</b> at Google. 
      He graduated two years ago, but still wants to learn new things, 
      especially in the technology field. 
      To do this, he uses various media such as apps, magazines, 
      and podcasts to <b>stay up to date</b> and <b>know what the future has to offer</b>. 
      His favorite apps are YouTube and Reddit. When Timm isn't working, 
      he loves to do <b>sports</b> or <b>meet friends in a bar</b>. 
      His big dream is to <b>have his own tech company</b> one day.
</p>



      <h3>Research</h3>
      <p> Some of our key findings include: </p>
      <p><i>  • Videos can be effective in classroom settings because 91% of school-age children are familiar with them (Murray, 2012) </i>
      <br></br>
      <i>  •“96% of university students preferred using their mobile devices for learning on the move” (Teodorescu, 2015)</i></p>

      <h3>MVP</h3>
      <p>Our primary goal was to develop a learning application for quantum computing, 
        integrating a blend of learning methods including videos, quizzes, and summaries. 
        Ensuring user engagement was crucial, hence our plan to gamify the app with rewards, multiple levels, and experience 
        points for advancing through different stages.
       </p>
       <p style={{color: "#8A81BD"}}><b>1. Different learning methods</b></p>
       <p>Different people have different learning styles and preferences, and different topics 
        may require different approaches to be effectively learned and understood</p>

      <p style={{color: "#5B46CE"}}><b>2. Mascot</b></p>
       <p>To build brand recognition, create an emotional connection between the users and the 
        app and add more personality </p>

       <p style={{color: "#140088"}}><b>3. Including a reward system</b></p>
       <p>Motivate users to engage with the app more frequently and for longer periods. Users may
        also feel a sense of accomplishment or progress as they earn rewards. </p>
        
      
   <h3>Low Fidelity Prototype</h3>

  <p> The first group meetings were characterized by many great suggestions, 
   confusion and overwhelm due to the theory of quantum computers and an ever 
   more comprehensive app idea. After filtering out the best elements and ideas with the
    help of the design method “Crazy 8’s”, the vision of our app started to get clearer 
    and clearer. </p>

   {/* <img src={MiltonLFD} className='MiltonWireframe' alt="MiltonWireframe" /> */}

   <ImageWithPlaceholder className='MiltonWireframe'
        src={MiltonLFD}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Milton Low Fidelity Prototype"
      />


   <h3>Wireframe</h3>
   <p>After the paper prototype was completed, we set about developing a more detailed wireframe. </p>
  
   {/* <img src={MiltonWireframe} className='MiltonWireframe' alt="MiltonWireframe" /> */}
   <ImageWithPlaceholder className='MiltonWireframe'
        src={MiltonWireframe}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="MiltonWireframe"
      />

    <h3>Key Findings From Interviews</h3>
    <p>During every stage we conducted some user research, 
      and interviews to gain quick feedback. </p>

      <b> 1. Onboarding experience: How to introduce user to the app?</b>
      <p>Problem: </p>
      <p>
      • There was no introduction in the beginning. The main critique was the lack of guidance 
      when opening the app for the first time. Another point was that the user has to assess 
      his level of expertise by himself.  </p>
      <p>Solution: </p>
      <p>
      • Introduce our app’s mascot – Schrödinger's cat, who greets the user and leads them through the app.  <br></br> 
      • User goes through level 0 first as a glimpse before having to sign up to continue</p>

      <b> 2.  What happens when the user answers a question incorrectly?</b>
      <p>Problem:</p>
      <p>
      • Our interviewees were wondering if they would know immediately if they answered correctly/ incorrectly, 
      or if they have to wait until the end to see  </p>
      <p>Solution: </p>
      <p>
      • Separate each level into 2 parts: learning and testing. <br></br> 
      • After learning, user gets to put their knowledge right to test and do a quick test. If they answer a 
      question incorrectly, Milton would let them know immediately. </p>

      <b>  3.  How to really motivate the user?</b>
      <p>Problem:</p>
      <p>
      • What should the user do with the rewards (coins/diamonds)?<br></br>
      • The stars weren’t motivating<br></br>
      • How to show the user’s progress in an interesting way? </p>
      <p>Solution: </p>
      <p>
      • Rewards in the form of coins with which you can use to buy clothes for Milton. <br></br> 
      • Show the progress/map in the form of a quantum computer with different backgrounds.  </p>

      <h3>Information Architecture</h3>
     <p>Based on the feedback from the interviews, we updated our information architecture and user flow.
     In addition, with the help of the user study, we asked potential users about topics on which the team 
     was still divided, such as displaying user errors. After the interviews, we adjusted our
    wireframe prototype using the user feedback. 
     </p>

     {/* <img src={MiltonIA} className='MiltonIA' alt="MiltonIA" /> */}
     <ImageWithPlaceholder className='MiltonIA'
       src={MiltonIA}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Milton Information Architecture"
      />

    <hr/>

    <h2>04. DESIGN SYSTEMS</h2>
      {/* <div className='DesignSystem'>
        <div className='Colors'>
        <img src={ColorsMilton} className='' alt='ColorsMilton'></img>
        </div>

        <div className='Colors'>
        <img src={TypoMilton} className='' alt='ColorsMilton'></img>
        </div>

        <div className='Colors'>
        <img src={SpacingMilton} className='' alt='ColorsMilton'></img>
        </div>
      </div> */}
       <a href="https://www.figma.com/proto/2i9FLhBsXMKQlGOwk8Jclx/UID?page-id=545%3A1334&type=design&node-id=4086-539&viewport=607%2C4%2C0.09&t=UJZdhiW8etYICiEj-1&scaling=scale-down&starting-point-node-id=4086%3A539&mode=design" target="_blank" rel="noopener noreferrer">
        <button className='Prototype'>Go to Design System</button>
      </a>
         <iframe className="iFrame" width="100%" height="450" 
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F2i9FLhBsXMKQlGOwk8Jclx%2FUID%3Fpage-id%3D545%253A1334%26type%3Ddesign%26node-id%3D4086-539%26viewport%3D387%252C264%252C0.04%26t%3DmE7DKKIFlda6fphC-1%26scaling%3Dcontain%26mode%3Ddesign" allowfullscreen>
        </iframe> 


     <hr/>
      <h2><span>03.</span> END RESULTS</h2>

      <p>We had issues getting 
        away from the rather boring, old-fashioned design of the wireframe prototype. But after 
        spending hours questioning, we found a solution 
        we all agreed on. All that was missing was a memorable onboarding and a creative level overview. 
        We reevaluated design ideas from the beginning of the project, and decided to build the levels 
        around an elevator in a quantum computer. </p>

        <p>After our last presentation, we were in a creativity slump. It was hard to build upon 
          what we already had, but we were motivated to improve our UX and UI even more. We started working 
          on changing the elevator based on the level the user is playing to give them a sense of progress. 
          The progress can now be seen in a hologram of the quantum computer inside the elevator. The more 
          levels you did, the more the projection will be filled up. We also changed some visual details 
          and some interactions inside the levels. It was also very important for us to conduct interviews 
          with potential users before submitting the final product. With the feedback from the interviews, 
          we were able to significantly improve the usability and user experience of “Milton” even more.</p>

      {/* <img src={MiltonPro} className='MiltonWireframe' alt="MiltonWireframe" /> */}
      <ImageWithPlaceholder className='MiltonWireframe'
       src={MiltonPro}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Milton Prototype"
      />

      <hr/>
      <h2>04. LEARNINGS</h2>
      <h3>Challenges</h3>
      <p>One of the challenges we encountered was finding the middle ground between a learning app and a mobile
         game. It was really hard for us to find a new design style after creating the wireframe. We were stuck 
         in the plain and somewhat boring layout, which made it difficult to create interesting and engaging visuals. 
      </p>

      <h3>Wins</h3>
      <p>A great success of the project was that we valued a good onboarding experience from the very beginning. 
        This enabled us to continuously revise our onboarding and come up with new ideas that completely changed our 
        User Experience. We are proud to have designed all the graphics of the prototype ourselves. It took a lot of 
        time and some of us were working with programs like Adobe Illustrator for the first time, but the effort definitely 
        paid off. The numerous user studies also helped us enormously and led us to the result we are all very happy with. 
        We achieved our goal: to design a learning app about quantum computers that creates a novel learning experience 
        to the users while motivating them.	
      </p>

      <h3>Key Learnings</h3>
      <p>One of the challenges we encountered was finding the middle ground between a learning app and a mobile
         game. It was really hard for us to find a new design style after creating the wireframe. We were stuck 
         in the plain and somewhat boring layout, which made it difficult to create interesting and engaging visuals. 
      </p>

      <h4>Be open to new ideas and learn how to let go of old ones.</h4>
      <p>Throughout the semester, we had many interesting ideas, which we sometimes held on to for too long. We spent too 
        much time on designs that were later discarded anyway. It was also important for us to remember that we are only 
        designers and not the actual users of Milton. This brings us to the next point: </p>

      <h4>User surveys are essential. </h4>
      <p>We did several user surveys spread throughout the design process. Because of this we were able to obtain good feedback 
        and clear up any uncertainties on our part. Usability issues such as unclear navigation or too long texts were also solved 
        this way. The feedback from our classmates and Dima's suggestions also contributed. 
      </p>

      <h4>Communication is the key. </h4>
      <p>We harmonized very well from the beginning as a group, which was also reflected in our workflow and of course in the final 
        product. Sometimes we “wasted” a lot of time choosing the color of a button. But in the end it was worth it to discuss such 
        details in order to move the design forward. </p>


      <div style={{height: "8rem"}}></div>


   </div>

   </div>
  );
}

export default Milton