import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import App from './App';
import Milton from './pages/Milton';
import Budgetly from './pages/Budgetly';
import Beast from './pages/Beast';
import Schule from './pages/Schule';
import Team from './pages/Team';
import Tune from './pages/Tune';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> {/* Wrap your application with BrowserRouter */}
    <Routes> {/* Define your routes within Routes */}
      <Route path="/" element={<App />} />
      <Route path="/milton" element={<Milton />} />
      <Route path="/budgetly" element={<Budgetly />} />
      <Route path="/beast" element={<Beast />} />
      <Route path="/schule" element={<Schule />} />
      <Route path="/team" element={<Team />} />
      <Route path="/tune" element={<Tune />} />
    </Routes>
  </BrowserRouter>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();