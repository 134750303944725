import React from 'react';
import MyHome from '../photos/beast/MyHome.png'
import MyCarRed from '../photos/beast/MyCarRed.png'
import MyCarGreen from '../photos/beast/MyCarGreen.png'
import EConsumption from '../photos/beast/E_Consumption.png'
import PerformerCharts from '../photos/beast/PerformerCharts.png'
import HedonistCharts from '../photos/beast/HedonistCharts.png'
import CalendarHedonist from '../photos/beast/Calendars.png'
import './Beast.css';
import '../App.css';
import Navbar from './Navbar.js' ;
import Menubar from './Menubar.js';
import ImageWithPlaceholder from './ImageWithPlaceholder'; 
import Placeholder from '../photos/loading.png'; 

function Beast() {
  return (

    <div>
      <Navbar/>
      <Menubar/>
    <div className="Beast">

    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>BEAST 3.0</h1>
    <p>3.2023 - 07.2023</p>
    <p><b>Role</b>: Concept, Design, User Test</p>
    <p><b>Tools</b>: Figma, Illustrator</p>
    </div>
    </div>

    <div className='ProjImg3'>
    </div>

    <p>The overarching purpose of this collaborative student project, undertaken in partnership with Mercedes 
        and AMG, is to envision and develop an innovative solution that bridges the gap between the AMG 
        electric vehicle (EV) and the charging ecosystem. The project aims to transform the way customers 
        interact with their EVs, specifically focusing on the charging process and energy management within
        their homes.</p>

     <h2> 01. PROBLEM  </h2>
     <p>The automotive industry is undergoing a transformative shift towards sustainable 
        and electrified mobility solutions. With the growing emphasis on environmental 
        consciousness and the increasing popularity of electric vehicles, the market 
        demands innovative approaches to address the challenges associated with EV adoption. 
        One prominent issue is the integration of EVs into the charging ecosystem, 
        particularly concerning user interactions and energy management within customer homes.</p>
        
     <hr/>
     <h2>02. RESULT VIDEO </h2>
      <p>Let's start by taking a look at the end results</p>
      <p><b>Hint:</b> You can choose the flows on the left bar to see each feature more clearly</p>
      <video width="100%" controls>
      <source src="/static/media/VideoBeast.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

      <hr/>
      <h2>03. DESIGN PROCESS </h2>
      <h3>User Groups</h3>
      <p>First thing in the beginning of the project was that we needed to understand our customer groups: <b>the performers</b> and <b>the hedonists</b>. </p>
      
      <p>They were loose handedly given to us by the stakeholders. </p>

      <p> The performers are your traditional luxury car owners. They enjoy great success in their working career, have money to spend, and strive for status and recognition. 
    Age wise they are most likely to be anywhere from 30 to 60 years old. In a car this persona seeks comfort, luxury, status and reliability. </p>

    <p> The hedonist on the other hand values experience over product status or wealth. This persona is much younger in a sense of advertising target group for luxurious items.</p>

    <h3>Ideation</h3>

     <p>Our first initial ideas were to create a system where you control your car's energy consumption and manage data with a smartphone application. We then quickly discovered that Mercedes had already implemented an application for data 
        management: Mercedes Me. It was still missing some key elements that are needed for managing connection between home, car and a smart grid, so we then decided to keep innovating with the idea. The smartphone application changed to 
        BEAST 3.0 Dashboard, which is meant to serve the purpose for smart home screen and the car dashboard. </p>

<p>Next implementation we came up with was to improve user experience of charging the car, by adding visual cues of energy flow and charging levels, by lights and haptic feedback for the charging cable, creating synchronous flow with the 
    car and its environment. 
</p>


<h3>Research and Imagine </h3>


<p> Then we had to start understanding how both of the personas would be using the improvements. After that, we had to make it as intuitive as possible for them. </p>

<p> The charging cables function for them comes from the same source but for different reasons: For the hedonist the cable indicates <b>instant feedback </b> of the charge, but for the performer it means <b>class and quality</b> over cheaper models. </p>

<p>  The dashboard was more tricky, and we had to start from the daily routines of our personas. How would they use the dashboard and for what reasons? We studied the type of personas of our customer groups, and came up with 
    stereotypical daily routines for both of them. Then we started to revamp widgets and create automation depending on how the kind of person would be using the dashboard. Hedonistic users are more spontaneous, and simplifying 
    the data is more beneficial, while the performer is more likely to be interested in all kinds of data and be in charge. </p>

<p> Out of their needs, we created two versions (or views) for the dashboard; both consisting of the same information, but for hedonist more tightly packed, and for the performer with more data showing within the main screen. We also thought
     about the spontaneity and added more possibilities to explore close by, for the hedonist. </p>


    <hr/>
    <h2>04. DASHBOARD </h2>
    <p>The Dashboard is a dynamic prototype, crafted using Figma, which serves as a control center for managing and visualizing your car's energy consumption and usage patterns.
    The Dashboard consists of four customizable widgets tailored each for the needs of both hedonists and performers:
    </p>

    <h3>My Car / My Home</h3>
    <p>At the forefront, we have the "My Car" screen, offering crucial insights into your car's battery status. It showcases battery charge in terms of percentages and kWh, 
    along with an estimate of the remaining travel distance. Additionally, you can manage your car's charging status directly from this screen. By tapping "My Home," you 
    gain insight into your home's energy flow and even channel excess energy from your car to power your home.
    </p>

    {/* <img src={MyHome} className='MyHome' alt="asset" /> */}
    <ImageWithPlaceholder className='MyHome'
       src={MyHome}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />
    {/* <img src={MyCarRed} className='MyCarRed' alt="asset" /> */}
    <ImageWithPlaceholder className='MyCarRed'
      src={MyCarRed}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />
    {/* <img src={MyCarGreen} className='MyCarRed' alt="asset" style={{marginLeft: "1%"}}/> */}
    <ImageWithPlaceholder className='MyCarRed' style={{marginLeft: "1%"}}
         src={MyCarGreen}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />

    <h3>Energy Widget</h3>
    <p>The "Energy" widget provides real-time data on energy grid prices, allowing you to optimize your car charging schedule. It intelligently suggests optimal charging 
    times throughout the day. The three charts are a chart with live updates on the energy grid prices, monthly financial savings, and monthly energy consumption. 
    Nevertheless, there are differences between the Hedonist and Performer versions.
    </p>

    <h4>For the Hedonist</h4>
    <p>Considering the characteristics of the Hedonist user profile, this widget adopts a minimalist design, showing only essential information without delving into 
        extensive detail. However, users have the option to access more comprehensive data by clicking on "View All Charts."
    </p>
    {/* <img src={HedonistCharts} className='MyHome' alt="asset" /> */}
    <ImageWithPlaceholder className='MyHome'
       src={HedonistCharts}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />

    <h4>For the Performer</h4>
    <p>Tailored for the Performer user profile, this widget provides in-depth data to deliver a more informative experience.
    </p>
    {/* <img src={EConsumption} className='MyHome' alt="asset" />
    <img src={PerformerCharts} className='MyHome' alt="asset" /> */}
    <ImageWithPlaceholder className='MyHome'
       src={EConsumption}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />
       <ImageWithPlaceholder className='MyHome'
       src={PerformerCharts}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />


    <h3>Calendar</h3>
    <p>The "Calendar" widget syncs with the user’s calendar to display potential impacts on their car's battery, issuing warnings if their battery might not suffice 
        for the next trip. Additionally, there are extra features in the Hedonist version that the Performer version lacks. </p>

    <p>Based on the Hedonist user's spontaneous personality, this feature strives to enhance their flexibility. It aids in discovering nearby charging stations 
    and activities within their current area, allowing them to enjoy their experiences without having to worry about running out of energy or finding the next activity. </p>
    {/* <img src={CalendarHedonist} className='MyHome' alt="asset" /> */}
    <ImageWithPlaceholder className='MyHome'
        src={CalendarHedonist}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Descriptive text for the image"
      />


    <h3>Suggestions</h3>
    <p>This widget serves as a guide to help users make environmentally and financially conscious decisions. </p>

    <h3>High Fidelity Prototype</h3>
      <p>This is our final prototype after conducting user tests </p>
      <p className='Hint'><b>Hint:</b> You can choose the flows on the left bar to see the dashboard view for each user group</p>
      <iframe className="iFrame" style={{border: "1px solid rgba(0, 0, 0, 0.1)"}} 
         width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F19G1IAgHqQZySPazkrHpSO%2FFinal-Prototype%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2639%26viewport%3D-226%252C-125%252C0.31%26t%3Dek7DQqs4WGqFVTGP-1%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A2003%26show-proto-sidebar%3D1%26mode%3Ddesign" 
         allowFullScreen></iframe>
      <a href="https://www.figma.com/proto/MXC9KdyzcuNJHQOipbWivx/Final-Prototype-(2)?page-id=0%3A1&type=design&node-id=1-2639&viewport=399%2C350%2C0.04&t=NVTSxRqILIhxOMSn-1&scaling=scale-down&starting-point-node-id=1%3A2003&show-proto-sidebar=1&mode=design" target="_blank" rel="noopener noreferrer">
        <button className='Prototype'>Go to Prototype</button>
      </a>

    <hr/>
    <h2>05. LEARNINGS </h2>

    <p>This project has been a valuable experience as it not only provided an opportunity to enhance my technical skills but also my soft skills. Throughout this journey, I gained insights into working on projects with stakeholders and collaborating effectively within a diverse team.
Several key takeaways from this experience stand out:
</p>

<p><b>Keeping stakeholders informed:</b> Initially, our team lacked experience working with stakeholders, as we were accustomed to university projects where we had full control over decision-making. This led to some challenges as we made decisions independently without consulting stakeholders. Therefore, our idea of the project timeline diverged from theirs, resulting in time constraints during certain project phases. However, we eventually managed to successfully complete the project.
</p>
<p>
<b>Consider the user perspective:</b> We learned to shift our focus from individual ideas to envisioning the entire user journey. By placing ourselves in the shoes of the end users, we gained a deeper understanding of their needs and expectations, which guided our ideation process.
</p>
<p>
<b>Think big and creatively:</b> We realized the importance of not limiting ourselves to what we deemed as "doable." Instead, we were encouraged to think boldly and creatively, exploring innovative solutions that may initially seem challenging.
</p>
<p>
In retrospect, this project has been a great learning experience, enriching both my technical and interpersonal skills while broadening my knowledge on new topics. 
</p>

<div style={{height: "8rem"}}></div>


    </div>


    </div>
);
}

export default Beast;