import cv from '../photos/CV.webp';

function Resume() {
    return (
      <a href="/static/media/CV.webp" style={{ textDecoration: 'none' }} target='blank'>
        <button style={{ borderRadius: '10px', border:'none', backgroundColor: 'white', padding: 5, cursor: 'pointer', outline: 'none'}}>
        <img src={cv} style={{width: "25px"}} alt="Clickable Button"  />
        </button>
      </a>
    );
  }
  
  export default Resume;