import mail from '../photos/mail.svg';

function Mail() {
    return (
      <a href="mailto:vianh.nguyen22@gmail.com?" style={{ textDecoration: 'none' }}>
        <button style={{ borderRadius: '10px', border:'none', backgroundColor: 'white', padding: 5, cursor: 'pointer', outline: 'none' }}>
        <img src={mail} alt="Clickable Button"  />
        </button>
      </a>
    );
  }
  
  export default Mail;
  