import './Menubar.css'; // Make sure the CSS file is correctly linked

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Menubar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();

    // Navigation functions
    const navigateTo = (anchor) => {
        navigate(`/${anchor}`);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="fullmenu">
            <button className="hamburger-icon" onClick={() => setIsOpen(!isOpen)}>
                &#9776;
            </button>

            <div className={`menu ${isOpen ? 'show' : ''} ${isScrolled ? 'scrolled' : ''}`}>
            <button className="close-menu" onClick={() => setIsOpen(false)}>X</button>
                <p onClick={() => navigateTo('#Home')}>Home</p>
                <p onClick={() => navigateTo('#About')}>About Me</p>
                <p onClick={() => navigateTo('#Portfolio')}>Portfolio</p>
                <p onClick={() => navigateTo('#Contact')}>Contact</p>
            </div>
        </div>
    );
};

export default Menubar;
