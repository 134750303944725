// import Illu from './Group 3.svg';
import React, { useState, useEffect } from 'react';
// import Illu from './photos/Asset 16.svg';
import Illu from './photos/Group 9.svg';
import Mail from './pages/Mail.js';
import Linkedin from './pages/Linkedin.js';
import Resume from './pages/Resume.js';
import Menubar from './pages/Menubar.js'
import { useNavigate } from 'react-router-dom'; 
//import Illu from './faceMe.svg';
import video from './photos/ezgif.com-gif-maker.gif'
import File from './photos/image 3.svg';
import Me from './photos/MePhoto.png';
import wave from './photos/wave.svg';
import TestPfp from './photos/TestPfp.png';
import './App.css';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import Navbar from './pages/Navbar';
import { useLocation } from 'react-router-dom';

function App() {

  const navigate = useNavigate();
  const navigateToMilton = () => {
    navigate('/milton'); 
  };

  const navigateToBudgetly = () => {
    navigate('/budgetly'); 
  };

  const navigateToBeast= () => {
    navigate('/beast'); 
  };

  const navigateToSchule= () => {
    navigate('/schule'); 
  };

  const navigateToTeam = () => {
    navigate('/team'); 
  };

  const navigateToTune = () => {
    navigate('/tune'); 
  };

  const location = useLocation();

  useEffect(() => {
    // Check if there is a hash in the URL
    if (location.hash) {
      const id = location.hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]); 

  return (
   
    <div>
      
      <Navbar/>
      <Menubar/>

      <div className='Photo' id='Home'>
        
        {/* <div className='UXFiles'>
        <img src={File} className='File' alt="asset" />
        <p>UI/UX Design</p>
        </div> */}

        <div className='Welcome' style={{position: "absolute", top: "40%", left: "50%",  transform: "translate(-50%, -50%)"}}>
        <h1> Hi, I'm Vi Anh
       </h1>
       <p> A Mobile Media student based in Stuttgart, Germany
       </p>
       </div>
        <img src={Illu} className='Illustration' alt="asset" />

      </div>

      <div className="About" id='About'>
        <h2 style={{color: "black", textAlign: "center", paddingTop: "1rem"}}>About Me</h2>
        </div>

        <div className='AboutMeIntro'>
       
        <div style={{ width:'300px', height: '300px', borderRadius: '50%', overflow: 'hidden' }} alt="asset">
        <img src={Me} style={{width:'300px', height: '300px', objectFit: 'cover' }}  ></img>
        </div>

        <div className='MyIntro'> <p style={{fontSize: "large"}}>Hi, I'm a student at the University of Media Stuttgart studying Mobile Media. 
        My major combines application, web, and software development—as exemplified by this very website!—with a strong emphasis on UI/UX design.
          I am currently in my 6th semester and am on an exchange at the University of Technology Sydney, 
          studying User Experience, Data Analytics and Business. During my studies, I had the chance to improve 
          my technical skills and critical thinking abilities to develop solutions that are not only aesthetically 
          pleasing, but also functional and user-centered. With a passion for design and a strong work ethic, I am excited 
          to work in a professional setting and make meaningful contributions.</p> 
          {/* <a href="/static/media/CV.pdf" target="_blank" rel="noopener noreferrer" type='pdf'>
        <button className='Button'>My Resume</button>
      </a> */}
         
         
          
        </div>
        
        </div>
      

      
        

    

      <div className="Portfolio" id='Portfolio'>
        <h2 style={{color: "black", textAlign: "center", paddingTop: "2rem"}}>Featured Projects</h2>

        <div style={{display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center", paddingBottom: "2rem"}}>
       
        </div>

        <div className="Projects">


        <div onClick={navigateToSchule} className='Child Section4'>
            <div className='Text'> 
              <h2 className="ProjHeader">Spuren Im Netz</h2>
              <p style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
              <p style={{display: "inline-block", backgroundColor: "#20384E", color:"white", borderRadius: "15px", padding: "8px", margin: "5px", fontSize: "smaller"}}>Usability</p>
            </div>
          </div>
        
        <div onClick={navigateToMilton} className='Child Section1'>
              <div className='Text'> 
              <h2 className="ProjHeader" style={{padding: "0px"}}>Milton</h2>
              <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
              </div>
         </div>
        
          <div onClick={navigateToBeast} className='Child Section3'>
            <div className='Text'> 
              <h2 className="ProjHeader">B.E.A.S.T 3.0</h2>
              <p style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
              <p style={{display: "inline-block", backgroundColor: "#20384E", color:"white", borderRadius: "15px", padding: "8px", margin: "5px", fontSize: "smaller"}}>Usability</p>
            </div>
          </div>

         <div onClick={navigateToBudgetly} className='Child Section2'>
            <div className='Text'> 
              <h2 className="ProjHeader">Budgetly</h2>
              <p style={{display: "inline-block", backgroundColor: "#033F63", color:"white", borderRadius: "15px", padding: "8px", fontSize: "smaller"}}>IOS App Development</p>
            </div>
          </div>

          <div onClick={navigateToTune} className='Child Section5'>
            <div className='Text'> 
              <h2 className="ProjHeader">Tune Mood</h2>
              <p style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI Design</p>
            </div>
          </div>

          <div className='Child Section6'>
            <div className='Text'> 
            <p style={{color: "white", fontSize: "smaller"}}>BEING UPDATED</p>
            <h2 className="ProjHeader" style={{color: "white"}}> SustainaBite</h2>
              <p style={{display: "inline-block", backgroundColor: "grey", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}>UI/UX Design</p>
            </div>
          </div>

        </div>
        </div>


        <div className="About" id='Contact'>
        <h2 style={{color: "black", textAlign: "center", paddingTop: "1rem"}}>Contact </h2>
        </div>

        <div className='Contact'>
       
        <div> 
       <div style={{display: "flex",  gap: "12px", alignItems: "center", textAlign: "center", justifyContent: "center"}}>
        <Mail />
        <Linkedin />
        {/* <Resume /> */}
        </div>
        </div>

        </div>

       <div className='Rights'>
          <p>@2024 VI ANH NGUYEN | ALL RIGHTS RESERVED</p>
        </div>
       


        

        {/* <div className="button-container">
        <button className='SeeAllProj'>See All Projects</button>
        </div> */}


    </div>

    
     
    
  );
}


export default App;
