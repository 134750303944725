import React, { useState, useRef, useEffect } from 'react';

function ImageWithPlaceholder({ src, placeholderSrc, onLoad, ...props }) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const onLoadRef = useRef(onLoad);

  useEffect(() => {
    onLoadRef.current = onLoad;
  }, [onLoad]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgSrc(src);
      if (onLoadRef.current) {
        onLoadRef.current();
      }
    };
    img.src = src;
  }, [src]);

  return <img src={imgSrc} {...props} />;
}

export default ImageWithPlaceholder;