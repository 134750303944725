import linkedin from '../photos/linkedin.png';

function Linkedin() {
    return (
      <a href="https://de.linkedin.com/in/vi-anh-nguyen-432a8025a" style={{ textDecoration: 'none' }} target='blank'>
        <button style={{ borderRadius: '10px', border:'none', backgroundColor: 'white', padding: 5, cursor: 'pointer', outline: 'none'}}>
        <img src={linkedin} style={{width: "25px"}} alt="Clickable Button"  />
        </button>
      </a>
    );
  }
  
  export default Linkedin;