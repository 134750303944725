import React from 'react';
import Navbar from './Navbar.js' ;
import Menubar from './Menubar.js' ;
import Moodboard from '../photos/tune/Moodboard.png';
import Sophia from '../photos/tune/Sophia.png';
import IATune from '../photos/tune/IATune.svg';
import LFTune from '../photos/tune/LFTune.svg';
import CODTune from '../photos/tune/CODTune.svg';
import WFTune from '../photos/tune/WFTune.svg';
import HFDTune from '../photos/tune/HFD.png';
import ImageWithPlaceholder from './ImageWithPlaceholder'; 
import Placeholder from '../photos/loading.png'; 

import './Tune.css'

function Tune() {
  return (

    <div>
      <Navbar/>
      <Menubar/>
      <div className="Budgetly">

    <p className='Category' style={{display: "inline-block", backgroundColor: "#070325", borderRadius: "15px", padding: "8px", color: "white", fontSize: "smaller"}}> UI Design </p>
    <div style={{display: "flex"}}>
    <div style={{display: "block"}}>
    <h1>TuneMood</h1>
    <p>10.2023 - 02.2024</p>
    <p><b>Role</b>: Concept, Full Stack Development</p>
    <p><b>Tools</b>: Intellij IDEA </p>
    </div>
    </div>

    <div className='TuneImg'>
    </div>

    <p>As part of the course "Mobile Interaction Design" in my second semester, I 
        created a prototype for a music app that generates personalized song 
        recommendations based on the user's mood input. The app has a feature where 
        users can select their current mood, such as happy, sad, energetic or calm.  
        The app will then use this input to suggest songs that match the user's current
        emotional state. 
         .</p>

         <p>In addition to the mood-based recommendations, the app will also include 
            all the standard features of a music app. Users are able to create playlists, 
            search for songs and artists, and browse through different genres. Additionally, 
            the app has a mood tracking feature that displays a chart of the user's emotion 
            throughout the day. 
         </p>

    <h2> 01. MOODBOARD </h2>
    {/* <img src={Moodboard} className='MiltonWireframe' alt="MiltonWireframe" /> */}
    <ImageWithPlaceholder className='MiltonWireframe'
        src={Moodboard}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Moodboard"
      />

    <hr />
    <h2> 02. PERSONA </h2>

        <div className='PersonaTune'>
       
        <img src={Sophia}  alt="asset" /> 

        <div className=''> 
        <h3> Bio </h3>
        <p>Sophia is a music and art enthusiast and introvert. Currently residing with her parents in 
          Cologne, she attends univeristy to pursue a degree in product design. Her ultimate aspiration
          is to move to New York City and live independently in her own apartment. Sophia is a sensitive
          person who often overthinks and finds solace in music. When she feels gloomy or starts to overthink, 
          she withdraws from social interactions and struggles to express her emotions. However, when she's
          in good spirits, Sophia is always eager to try new things. She recently started journaling as a way to 
          deal with her emotions and keep track of her mood. But Sophia is not keen on writing on wants to find
          other alternatives. </p> 
        <h3> Goals </h3>
        <p>• Travel as much as possible </p>
        <p>• Making new friends and experiences </p>
        <p>• Live in New York </p>
        <h3> Frustations </h3>
        <p>• Fear of failure </p>
        <p>• Fear of loneliness </p>
        
        </div>

      </div>
      <h3> Scenario </h3>
      <p>Sophia likes to listen to music in the morning to get ready for the day. Upon opening the app MoodTune, 
        she is greeted with a "Good Morning" text and a question about how she is feeling. Since she had a good sleep, 
        she picks "Happy" and "Excited", and the app <b>generates recommendations accordingly</b> . Sometimes, when she 
        already knows which song she wants to listen to, <b> she simply selects it and let the app know how that 
        particular song makes her feel.</b> </p>
      <p>Sophia often receives notifications from her best friend about new songs or playlist, and she can <b> listen to them 
        and add them to her collection.</b> </p>   Usually, she listens to her playlists that she has created in the library. 
        However, on days when she wants to explore new music, she <b>browses through the app's home screen or genres in search</b>.
        And if she finds a song she likes, she adds it to her library and <b>sends it to her best friends. </b>
    <hr />
    <h2> 03. INFORMATION ARCHITECTURE </h2>

    {/* <img src={IATune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}
    <ImageWithPlaceholder style={{width:"100%", marginTop:"1rem"}}
        src={IATune}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Tune Information Architecture"
      />

    <hr />
    <h2> 04. LOW FIDELITY PROTOTYPE </h2>
    <p>Click <a href="https://marvelapp.com/prototype/b20f35d" target="_blank">here</a> for the interactive prototype:  </p>
    
    {/* <img src={LFTune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}
    <ImageWithPlaceholder style={{width:"100%", marginTop:"1rem"}}
        src={LFTune}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Tune Low Fidelity Prototype"
      />

    <hr />
    <h2> 05. COLLECTION OF DATA </h2>
    <p>I used this method to brainstorm and find out all necessary components of the app</p>

    {/* <img src={CODTune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}
    <ImageWithPlaceholder style={{width:"100%", marginTop:"1rem"}}
        src={CODTune}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Tune Low Fidelity Prototype"
      />

    <hr />
    <h2> 06. WIREFRAME </h2>

    {/* <img src={WFTune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}
    <ImageWithPlaceholder style={{width:"100%", marginTop:"1rem"}}
       src={WFTune}  
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Tune Low Fidelity Prototype"
      />

    <p>Following user tests on the mid-fidelity prototype, I decided to remove the news page. It was
      deemed unnecessary and would have required too much effort within the timeframe of the project. 
      In addition, I made some design changes based on user feedback to enhance the user experience and 
      create a more visually appealing user interface. 
    </p>

    <hr />
    <h2> 07. HIGH FIDELITY PROTOTYPE </h2>
    {/* <img src={HFTune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}
    {/* <img src={HFDTune}  style={{width:"100%", marginTop:"1rem"}} alt="asset" /> */}

    <ImageWithPlaceholder style={{width:"100%", marginTop:"1rem"}}
        src={HFDTune}
        placeholderSrc={Placeholder}
        onLoad={() => console.log('Image loaded!')}
        alt="Tune Low Fidelity Prototype"
      />

    <div style={{height: "8rem"}}></div>

    </div>


    </div>
);
}

export default Tune;