import './Navbar.css';
import { useNavigate } from 'react-router-dom'; 
import React, { useState, useEffect } from 'react';

function Navbar() {

    const navigate = useNavigate();
    const navigateToPortfolio = () => {
      navigate('/#Portfolio'); 
    };
    const navigateToAbout = () => {
        navigate('/#About'); 
      };

      const navigateToHome = () => {
        navigate('/#Home'); 
      };

      const navigateToContact = () => {
        navigate('/#Contact'); 
      };

      const [isScrolled, setIsScrolled] = useState(false);

      useEffect(() => {
        const handleScroll = () => {
          const isShow = window.scrollY > 50;
          setIsScrolled(isShow);
        };
    
        // Add event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

return (
<div className={`Navbar ${isScrolled ? 'scrolled' : ''}`}>
<p  onClick={navigateToHome}>Home</p>
<p  onClick={navigateToAbout}>About Me</p>
<p  onClick={navigateToPortfolio}>Portfolio</p>

<p onClick={navigateToContact}>Contact</p>
</div>
);
}

export default Navbar;